import {BASE_URL} from '../constants/constants';
import {StatusCodes} from 'http-status-codes';
import {FindYourStyleType} from '../types/products/types';
import fetchJsonp from 'fetch-jsonp';

enum FetchStatus {
    DEFAULT = -1,
    SUCCESS,
    ACTIVE,
    ERROR
}

export default FetchStatus;


/**
 * Enumeration for the request method types which can be used.
 *
 * @type {{GET: string, POST: string, PUT: string, DELETE: string}}
 */
export enum RequestMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
}


export const wait = async (delay: number): Promise<void> => new Promise((resolve: Function) => setTimeout(resolve, delay));

const RetryHttpCodes = [StatusCodes.BAD_GATEWAY, StatusCodes.GATEWAY_TIMEOUT, StatusCodes.SERVICE_UNAVAILABLE];

export const checkServerResponse = async <T>(request: () => Promise<Response>, withRetry: boolean = true): Promise<T> => {
    let retries = 0;
    let retry = true;
    while (retry) {
        const response = await request();
        let returnResponse = ({} as T);
        if (response && response.status >= StatusCodes.OK && response.status < StatusCodes.BAD_REQUEST) {
            if (response.headers.get('content-type')?.includes('application/json')) {
                if (response.status !== StatusCodes.NO_CONTENT) {
                    returnResponse = await response.json();
                }
            } else if (response.headers.get('content-type')?.includes('text/html')) {
                const text = await response.text();
                if (text) {
                    returnResponse = text as any;
                }
            } else {
                const blob = await response.blob();
                if (blob) {
                    returnResponse = blob as any;
                }
            }
            return returnResponse;
        }
        if (withRetry && retries < 5 && RetryHttpCodes.includes(response.status)) {
            const duration = Math.pow(2, retries) * 1000;
            console.log(`Waiting ${duration}ms before retrying.`);
            await wait(duration);
            retries++;
            retry = true;
        } else {
            throw new Error(await response.text());
        }
    }
    throw new Error();
};

/**
 * Defines the header which should be used in each request.
 *
 * @return {{Accept: string, ContentType: string}} An object which contains the headers which will be used in a request.
 */
export const defaultHeaders = () => ({
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    'Pragma': 'no-cache'
});

/**
 * Helper method to get the default request configuration.
 *
 * @param method The used HTTP request method.
 * @param data The data to send in the body of the request.
 * @param jwt The current jwt of the user.
 * @param credentials Whether credentials should be included or not.
 * @return {{method: *, headers: *, body: *}} The default request configuration.
 */
export const requestConfig = (method: any, data?: any, jwt?: string, credentials: boolean = true) => ({
    method: method,
    headers: jwt ? {...defaultHeaders(), 'Authorization': `Bearer ${jwt}`} : defaultHeaders(),
    body: data ? JSON.stringify(data) : null,
    credentials: credentials ? 'include' : undefined as RequestCredentials | undefined
});


/**
 * Define the routes which can be called.
 */
export const Route = {
    GetProducts: '/api/v1/products',
    GetProduct: (id: number) => `/api/v1/products/${id}`,
    GetColors: '/api/v1/products/colors',
    GetSizes: '/api/v1/products/sizes',
    GetCountries: '/api/v1/products/countries',
    GetProductTypes: '/api/v1/products/productTypes',
    GetProductColors: (productIDs: number[]) => `/api/v1/products/productColors/${JSON.stringify(productIDs)}`,
    Login: '/api/v1/user/login',
    Register: '/api/v1/user/register',
    UserInfo: '/api/v1/user/info',
    Logout: '/api/v1/user/logout',
    ResetPassword: '/api/v1/user/resetPassword',
    UpdatePassword: '/api/v1/user/updatePassword',
    GetFindYourStyle: (type: FindYourStyleType) => `/api/v1/products/findYourStyle/${type.toString()}`,
    CreateOrder: '/api/v1/orders/create',
    SetCreditCardOrderAsPaid: '/api/v1/orders/setAsPaid',
    Contact: '/api/v1/contact/request',
    SearchStores: '/api/v1/stores/search',
    LoadOrders: '/api/v1/user/orders',
    LoadInstagramToken: '/api/v1/social/instagram',
    LoadInstagramPosts: '/me/media',
    AuthorizeKlarna: '/api/v1/orders/klarna/authorize',
    RegisterNewsletter: '/api/v1/user/newsletter/register',
    SubscribeNewsletter: '/api/v1/user/newsletter/subscribe',
    ValidateAddress: '/api/v1/orders/address/validate',
    ValidateCoupon: '/api/v1/orders/coupon/validate',
    LoadNewsBar: '/api/v1/newsBar',
    Blog: {
        GetCategories: '/api/v1/blog/categories',
        GetPostsByCategory: (categoryID: number) => `/api/v1/blog/posts/${categoryID}`,
        GetHighlights: '/api/v1/blog/posts/highlights',
        GetPost: (id: number) => `/api/v1/blog/post/${id}`,
        RecordPostView: '/api/v1/blog/post/view',
    },
    Tracking: {
        AddToCart: (productID: number) => `/api/v1/tracking/addToCart/${productID}`,
        InitiateCheckout: '/api/v1/tracking/initiateCheckout',
        AddPaymentInfo: '/api/v1/tracking/addPaymentInfo',
        SearchProducts: '/api/v1/tracking/searchProducts',
    },
    Pages: {
        GetPages: '/api/v1/pages',
    }
};

export const AdminRoute = {
    GetProducts: '/api/v1/admin/products',
    GetProduct: (id: number) => `/api/v1/admin/product/${id}`,
    GetColors: '/api/v1/admin/colors',
    GetProductTypes: '/api/v1/admin/productTypes',
    GetSizes: '/api/v1/admin/sizes',
    GetFindYourStyles: '/api/v1/admin/findYourStyle',
    GetOrders: (page: number, pageSize: number, search: string = '') => `/api/v1/admin/orders/${page}/${pageSize}/${search}`,
    UpdateColor: (id: number) => `/api/v1/admin/color/${id}`,
    UpdateOrCreateProduct: '/api/v1/admin/product',
    CreateColor: '/api/v1/admin/color',
    UploadImage: '/api/v1/admin/upload/image',
    UpdateProductOrder: '/api/v1/admin/products/order',
    UpdateColorOrder: '/api/v1/admin/colors/order',
    DeleteImage: (id: number) => `/api/v1/admin/image/${id}`,
    DeleteColor: (id: number) => `/api/v1/admin/color/${id}`,
    DeleteProduct: (id: number) => `/api/v1/admin/product/${id}`,
    UpdateFindYourStyles: (type: FindYourStyleType) => `/api/v1/admin/findYourStyle/${type.toString()}`,
    SetOrderAsPaid: '/api/v1/admin/orders/setAsPaid',
    SetOrderAsShipped: '/api/v1/admin/orders/setAsShipped',
    SetOrderAsCancelled: '/api/v1/admin/orders/setAsCancelled',
    EditOrder: (id: number) => `/api/v1/admin/orders/edit/${id}`,
    UploadStock: '/api/v1/admin/upload/stock',
    DownloadShippingConsignment: (ids: number[]) => `/api/v1/admin/orders/documents/consignment/${JSON.stringify(ids)}`,
    DownloadInvoices: (ids: number[]) => `/api/v1/admin/orders/documents/invoices/${JSON.stringify(ids)}`,
    DownloadReport: '/api/v1/admin/orders/documents/report',
    GetCoupons: (page: number, pageSize: number, automaticallyGenerated: boolean, search: string) => `/api/v1/admin/coupons/${page}/${pageSize}/${automaticallyGenerated}/${search}`,
    SaveCoupon: '/api/v1/admin/coupons/save',
    DeleteCoupons: '/api/v1/admin/coupons/delete',
    SetCouponsState: '/api/v1/admin/coupons/state',
    DuplicateCoupons: '/api/v1/admin/coupons/duplicate',
    GetNewsBarEntries: (page: number, pageSize: number) => `/api/v1/admin/newsBar/${page}/${pageSize}`,
    SaveNewsBarEntry: '/api/v1/admin/newsBar/save',
    Blog: {
        GetCategories: '/api/v1/admin/blog/categories',
        GetTags: '/api/v1/admin/blog/tags',
        SaveCategory: '/api/v1/admin/blog/category/save',
        DeleteCategory: '/api/v1/admin/blog/category/delete',
        GetPostsForCategory: (id: number) => `/api/v1/admin/blog/posts/${id}`,
        UploadBlogPostImage: '/api/v1/admin/blog/posts/image/upload',
        DeleteBlogPostImage: '/api/v1/admin/blog/posts/image/delete',
        SaveBlogPost: '/api/v1/admin/blog/posts/save',
        UpdateBlogPostHighlight: '/api/v1/admin/blog/posts/highlight',
        UpdateBlogPostVisibility: '/api/v1/admin/blog/posts/visibility',
        DeleteBlogPost: '/api/v1/admin/blog/posts/delete',
    },
    Pages: {
        GetPages: (page: number, pageSize: number) => `/api/v1/admin/pages/${page}/${pageSize}`,
        UploadPageElementFile: '/api/v1/admin/pages/file/upload',
        DeletePageElementFile: '/api/v1/admin/pages/file/delete',
        SavePage: (id: number) => `/api/v1/admin/pages/save/${id}`,
        PublishPage: (id: number) => `/api/v1/admin/pages/publish/${id}`
    }
}

export const convertObjectToGetParameters: Function = (object: Object): string => object ? Object.keys(object).map(key => `${key}=${typeof (object as any)[key] === 'string' ? (object as any)[key] : JSON.stringify((object as any)[key])}`).join('&') : '';


/**
 * Method which will call the given url with the given parameters. As result, an object is expected.
 *
 * @param url The URL which should be called.
 * @param params Object which will be converted to parameters.
 * @param jwt Optional token for authentication
 * @param credentials Whether credentials should be included or not
 * @param customBaseURL A custom base url which can be used.
 */
export async function get<U>(url: string, params?: Object | null, jwt?: string, customBaseURL?: string, credentials: boolean = true, method: 'fetch' | 'fetchJSONP' = 'fetch'): Promise<U> {
    if (method === 'fetchJSONP') {
        const response = await fetchJsonp(`${customBaseURL !== null && customBaseURL !== undefined ? customBaseURL : BASE_URL}${url}?${convertObjectToGetParameters(params)}`);
        return response.json();
    }
    return checkServerResponse<U>(() => fetch(`${customBaseURL !== null && customBaseURL !== undefined ? customBaseURL : BASE_URL}${url}?${convertObjectToGetParameters(params)}`, requestConfig(RequestMethod.GET, undefined, jwt, credentials)));
}

/**
 * Method which will post the body data to the given url. As response an object is expected.
 *
 * @param url The URL which should be called.
 * @param body The request body which will be sent to the server.
 * @param jwt Optional token for authentication
 */
export async function post<T, U>(url: string, body?: T, jwt?: string): Promise<U> {
    return checkServerResponse<U>(() => fetch(`${BASE_URL}${url}`, requestConfig(RequestMethod.POST, body, jwt)));
}

/**
 * Method which will post the body data to the given url. As response an object is expected.
 *
 * @param url The URL which should be called.
 * @param body The request body which will be sent to the server.
 * @param jwt Optional token for authentication
 */
export async function put<T, U>(url: string, body?: T, jwt?: string): Promise<U> {
    return checkServerResponse<U>(() => fetch(`${BASE_URL}${url}`, requestConfig(RequestMethod.PUT, body, jwt)));
}

/**
 * Method which will post the body data to the given url. As response an object is expected.
 *
 * @param url The URL which should be called.
 * @param body The request body which will be sent to the server.
 * @param jwt Optional token for authentication
 */
export async function deleteRequest<T, U>(url: string, body?: T, jwt?: string): Promise<U> {
    return checkServerResponse<U>(() => fetch(`${BASE_URL}${url}`, requestConfig(RequestMethod.DELETE, body, jwt)));
}

import {call, delay, put, select, spawn, takeLatest} from 'redux-saga/effects';
import {
    Address,
    CartElement,
    Checkout,
    CHECKOUT,
    CheckoutPayload,
    OrderResponse,
    PaymentMethod,
    SetAddressPropertyPayload,
    VALIDATE_COUPON,
    CartCoupon, CheckKlarnaOrderActionAndSaga,
} from '../reducer/cart/types';
import {AppState} from '../types/types';
import {denormalize} from '../helper/normalizeHelper';
import {Dictionary, PayloadAction} from '@reduxjs/toolkit';
import {Language} from '../reducer/application/types';
import {post,  Route} from '../api/api';
import {Country, Product} from '../types/products/types';
import {
    fetchCheckout,
    fetchCheckoutError,
    fetchCheckoutSuccess,
    fetchValidateCoupon,
    fetchValidateCouponError,
    fetchValidateCouponSuccess,
    resetValidateCouponFetchStatus,
    setCheckoutCompleted,
    setUpdatedBillingAddressProperties,
    updateBillingAddress,
    updateDeliveryAddress
} from '../reducer/cart/cart';
import {LoadUserActionAndSaga} from '../reducer/user/types';

const generateAddress = (address: Address) => {
    return {
        company: address.company?.trim(),
        firstName: address.firstName?.trim(),
        lastName: address.lastName?.trim(),
        street: address.street?.trim(),
        amendment: address.amendment?.trim(),
        zipCode: address.zipCode?.trim(),
        city: address.city?.trim(),
        countryID: address.country,
        phone: address.phone?.trim()
    }
}

function* checkout(action: PayloadAction<CheckoutPayload>) {
    // Only continue if no login should be created or the passwords do match
    if (!action.payload.createLogin || (action.payload.password?.trim() && action.payload.password?.trim() === action.payload.passwordConfirm?.trim())) {
        const {
            elements,
            checkout,
            language,
            payPalOrderID,
            coupons
        }: { payPalOrderID?: string, elements: Dictionary<CartElement>, checkout: Checkout, language: Language, coupons: string[] } = yield select((state: AppState) => ({
            ...state.cart,
            coupons: state.cart.coupons?.used?.map(it => it.code) || [],
            language: state.application.language
        }))
        const checkoutElements = denormalize(elements);
        if (checkoutElements.length) {
            const products = checkoutElements.map(it => ({
                productID: it.productID,
                colorID: it.colorID,
                sizeID: it.sizeID,
                quantity: it.amount
            }));
            const orderUser = {
                gender: checkout.billingAddress.gender,
                firstName: checkout.billingAddress.firstName?.trim(),
                lastName: checkout.billingAddress.lastName?.trim(),
                email: checkout.billingAddress.email?.trim()
            };
            const billingAddress = generateAddress(checkout.billingAddress);
            const deliveryAddress = generateAddress(checkout.deliveryAddress);
            const body = {
                orderUser,
                billingAddress,
                deliveryAddress,
                paymentMethod: checkout.paymentMethod,
                products,
                language,
                payPalOrderID,
                password: action.payload.password,
                passwordConfirm: action.payload.passwordConfirm,
                createAccount: action.payload.createLogin,
                registerNewsletter: action.payload.registerNewsletter,
                coupons
            }
            try {
                yield put(fetchCheckout());
                const response: OrderResponse = yield call(post, Route.CreateOrder, body);
                yield put(fetchCheckoutSuccess(response.order));
                // Check if user was created successfully, then log the user in
                if (action.payload.createLogin && response.user) {
                    yield put(LoadUserActionAndSaga.successAction(response.user));
                }
                if (checkout.paymentMethod === PaymentMethod.PAYMENT_IN_ADVANCE || checkout.paymentMethod === PaymentMethod.PAYPAL) {
                    // Since user does not have any more steps to do, complete the checkout.
                    yield put(setCheckoutCompleted());
                }
            } catch (e) {
                console.error(e);
                yield put(fetchCheckoutError());
            }
        }
    }
}

function* checkoutSaga() {
    yield takeLatest(CHECKOUT, checkout);
}

interface ValidateAddressResponse {
    countryCode?: string;
    street?: string;
    city?: string;
    zipCode?: string;
}

function* checkDeliveryAddressSaga() {
    yield takeLatest('cart/setDeliveryAddressProperty', function* (action: PayloadAction<SetAddressPropertyPayload<string | number>>) {
        // Wait 500ms to avoid massive server calling during user input
        yield delay(500);
        const {validate} = action.payload;
        if (validate) {
            try {
                // On country change, check if address is valid
                const {
                    deliveryAddress,
                    countries,
                }: { deliveryAddress: Address, countries: Country[] } = yield select((state: AppState) => ({
                    deliveryAddress: state.cart.checkout.deliveryAddress,
                    countries: denormalize(state.products?.countries || {})
                }));
                const response: ValidateAddressResponse = yield call(post, Route.ValidateAddress, deliveryAddress);
                if (response.countryCode) {
                    const country = countries.find(it => it.isoCode === response.countryCode);
                    if (country) {
                        // Also update delivery address if needed
                        yield put(updateDeliveryAddress({
                            country: country.id,
                            zipCode: deliveryAddress.zipCode,
                            city: deliveryAddress.city
                        }));
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }
    });
}


function* checkBillingAddressSaga() {
    yield takeLatest('cart/setBillingAddressProperty', function* (action: PayloadAction<SetAddressPropertyPayload<string | number>>) {
        // Wait 500ms to avoid massive server calling during user input
        yield delay(500);
        const {validate} = action.payload;
        if (validate) {
            try {
                // On country change, check if address is valid
                const {
                    billingAddress,
                    countries,
                    useBillingAddressAsDeliveryAddress
                }: { billingAddress: Address, deliveryAddress: Address, countries: Country[], useBillingAddressAsDeliveryAddress: boolean } = yield select((state: AppState) => ({
                    billingAddress: state.cart.checkout.billingAddress,
                    countries: denormalize(state.products?.countries || {}),
                    useBillingAddressAsDeliveryAddress: state.cart.checkout.useBillingAddressAsDeliveryAddress
                }));
                const response: ValidateAddressResponse = yield call(post, Route.ValidateAddress, billingAddress);

                if (response.countryCode) {
                    const country = countries.find(it => it.isoCode === response.countryCode);
                    if (country) {
                        const updatedBillingAddressProperties: string[] = [];
                        if (country.id !== billingAddress.country) {
                            updatedBillingAddressProperties.push('country');
                        }
                        yield put(updateBillingAddress({
                            country: country.id,
                            zipCode: billingAddress.zipCode,
                            city: billingAddress.city
                        }));
                        yield put(setUpdatedBillingAddressProperties(updatedBillingAddressProperties));
                        // Also update delivery address if needed
                        if (useBillingAddressAsDeliveryAddress) {
                            yield put(updateDeliveryAddress({
                                country: country.id,
                                zipCode: billingAddress.zipCode,
                                city: billingAddress.city
                            }));
                        }
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }
    });
}

function* validateCouponSaga() {
    yield takeLatest(VALIDATE_COUPON, function* ({payload}: PayloadAction<string>) {
        try {
            const {
                elements,
                checkout,
                language,
                stateProducts
            }: { elements: Dictionary<CartElement>, checkout: Checkout, language: Language, stateProducts: Dictionary<Product> } = yield select((state: AppState) => ({
                ...state.cart,
                language: state.application.language,
                stateProducts: state.products?.products || {}
            }))
            const checkoutElements = denormalize(elements);
            if (checkoutElements.length) {
                const products = checkoutElements.map(it => ({
                    productID: it.productID,
                    colorID: it.colorID,
                    sizeID: it.sizeID,
                    quantity: it.amount,
                    price: stateProducts[it.productID]?.price
                }));
                const orderUser = {
                    gender: checkout.billingAddress.gender,
                    firstName: checkout.billingAddress.firstName?.trim(),
                    lastName: checkout.billingAddress.lastName?.trim(),
                    email: checkout.billingAddress.email?.trim()
                };
                const billingAddress = generateAddress(checkout.billingAddress);
                const deliveryAddress = generateAddress(checkout.deliveryAddress);
                const cart = {
                    orderUser,
                    billingAddress,
                    deliveryAddress,
                    paymentMethod: checkout.paymentMethod,
                    products,
                    language
                }
                yield put(fetchValidateCoupon());
                const response: CartCoupon = yield call(post, Route.ValidateCoupon, {code: payload, cart});
                yield put(fetchValidateCouponSuccess(response));
                yield put(resetValidateCouponFetchStatus());
            }
        } catch (e) {
            console.error(e);
            yield put(fetchValidateCouponError({coupon: payload, message: (e as Error).message}));
        }
    });
}


export default function* cartSaga() {
    yield spawn(checkoutSaga);
    yield spawn(CheckKlarnaOrderActionAndSaga.saga!);
    yield spawn(checkBillingAddressSaga);
    yield spawn(checkDeliveryAddressSaga);
    yield spawn(validateCouponSaga);
}
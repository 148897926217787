import {createAction, Dictionary} from '@reduxjs/toolkit';
import {Gender, User} from '../user/types';
import FetchStatus, {put as putRequest, Route} from '../../api/api';
import {CouponLocalization, CouponType} from '../../types/products/types';
import {BaseErrorCode, createBasicSagaFetchAction, SagaHandling} from '../helper';
import {put} from 'redux-saga/effects';
import {setCheckoutCompleted} from './cart';


export const CHECKOUT = 'CART/CHECKOUT';

export const AUTHORIZE_KLARNA_ORDER = 'CART/AUTHORIZE_KLARNA_ORDER';

export const VALIDATE_COUPON = 'CART/VALIDATE_COUPON';


export enum PaymentMethod {
    PAYPAL = 'PAYPAL',
    CREDIT_CARD = 'CREDIT_CARD',
    KLARNA = 'KLARNA',
    PAYMENT_IN_ADVANCE = 'PAYMENT_IN_ADVANCE'
}

export interface SetAddressPropertyPayload<ValueType> {
    property: keyof Address;
    value: ValueType;
    validate?: boolean;
}

export interface OrderResponseOrder {
    paymentResponse: string;
    invoiceNumber: string;
}

export interface OrderResponse {
    order: OrderResponseOrder;
    user?: User;
}

export interface CheckoutPayload {
    password: string;
    passwordConfirm: string;
    createLogin: boolean;
    registerNewsletter: boolean;
}

export interface UpdateAddressPayload {
    country: number;
    city: string;
    zipCode: string;
}

export const checkout = createAction<CheckoutPayload>(CHECKOUT);

export interface KlarnaAuthorizePayload {
    client_token: string;
    authorization_token: string;
}

export const authorizeKlarnaOrder = createAction<KlarnaAuthorizePayload>(AUTHORIZE_KLARNA_ORDER);

export const validateCoupon = createAction<string>(VALIDATE_COUPON);

export interface ValidateCouponErrorPayload {
    message: string;
    coupon: string;
}

export interface CartElement {
    id: number;
    productID: number;
    sizeID: number;
    colorID: number;
    amount: number;
}

export interface Address {
    company?: string;
    firstName: string;
    lastName: string;
    street: string;
    amendment?: string;
    city: string;
    zipCode: string;
    email: string;
    gender?: Gender;
    country: number;
    phone?: string;
}

export interface Checkout {
    useBillingAddressAsDeliveryAddress: boolean;
    billingAddress: Address;
    deliveryAddress: Address;
    paymentMethod?: PaymentMethod;
    completed: boolean;
    tracked?: boolean;
    updatedBillingAddressProperties?: string[];
    updatedDeliveryAddressProperties?: string[];
}

export interface CartCoupon {
    code: string;
    value?: number;
    type: CouponType;
    absoluteValue?: number;
    legalText?: string;
    localizations: CouponLocalization[];
}

export enum CouponRestrictionType {
    SPECIFIC_PRODUCT = 'SPECIFIC_PRODUCT',
    SPECIFIC_COLORS = 'SPECIFIC_COLORS',
    MINIMUM_PRODUCT_QUANTITY = 'MINIMUM_PRODUCT_QUANTITY',
    MAXIMUM_USAGES_PER_USER = 'MAXIMUM_USAGES_PER_USER',
    MAXIMUM_ORDER_QUANTITY = 'MAXIMUM_ORDER_QUANTITY',
    NEW_CUSTOMER = 'NEW_CUSTOMER'
}

export interface CouponRestrictionConfiguration {
    id: number;
    type: CouponRestrictionType;
}

export interface CouponProductRestrictionConfiguration extends CouponRestrictionConfiguration {
    productID?: number;
    productIDs: number[];
    maximumNumberOfProducts?: number;
}

export interface CouponColorRestrictionConfiguration extends CouponRestrictionConfiguration {
    colorIDs: number[];
}

export interface CouponMaximumUsagesPerUserRestrictionConfiguration extends CouponRestrictionConfiguration {
    maximumUsages: number;
}

export interface CouponMaximumOrderQuantityRestrictionConfiguration extends CouponRestrictionConfiguration {
    maximumOrderQuantity: number;
}

export interface CouponMinimumOrderQuantityRestrictionConfiguration extends CouponRestrictionConfiguration {
    minimumNumberOfProducts: number;
}

export const CheckKlarnaOrderActionAndSaga = createBasicSagaFetchAction<KlarnaAuthorizePayload, void, BaseErrorCode>({
    actionGroup: 'cart',
    actionName: 'checkKlarnaOrder',
    sagaHandling: SagaHandling.Latest,
    networkCall: (requestBody) => {
        return () => putRequest(Route.AuthorizeKlarna, requestBody);
    },
    successGenerator: [function* (): Generator<any, any, any> {
        yield put(setCheckoutCompleted());
    }]
});

export interface CartState {
    elements: Dictionary<CartElement>;
    checkout: Checkout;
    paymentResponse?: string;
    fetchStatus: FetchStatus;
    confirmPaymentFetchStatus: FetchStatus;
    klarnaFetchStatus: FetchStatus;
    invoiceNumber: string;
    payPalOrderID: string;
    coupons?: {
        used?: CartCoupon[];
        invalid?: string[];
        fetchStatus?: FetchStatus;
        errorMessage?: string;
    }
}
import ReactPixel from 'react-facebook-pixel';

const options = {
    autoConfig: true,
    debug: false
};

let enabled = false;

const getFacebookPixel = () => {
    if (!enabled) {
        ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL || '', undefined, options);
        enabled = true;
    }
    return ReactPixel;
}

export default getFacebookPixel;
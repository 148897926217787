import React, {ReactNode} from 'react';
import {makeStyles} from 'tss-react/mui';
import {useDispatch} from 'react-redux';
import {setShowMobileMenu} from '../../../../reducer/application/application';
import {Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {ImageSet} from '../../../../types/types';
import Image from '../../../helper/Image';

interface MobileMenuItemProps {
    title: string;
    link: string;
    imageSet: ImageSet;
    onClick?: () => void;
    appendix?: ReactNode;
}

const useStyles = makeStyles()(theme => ({
    root: {
        ...theme.link,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#FFF',
        padding: theme.spacing(1, 1, 1, 2),
        marginBottom: theme.spacing(1),
        border: 0,
        width: '100%'
    },
    menuImage: {
        width: 56,
        height: 56
    },
    titleSurround: {
        display: 'flex',
        alignItems: 'center'
    }
}));

const MobileMenuItem: React.FC<MobileMenuItemProps> = ({title, link, imageSet, onClick, appendix}) => {
    const {classes} = useStyles();
    const dispatch = useDispatch();

    return (
        <Link
            className={classes.root}
            onClick={() => {
                dispatch(setShowMobileMenu(false));
                onClick?.();
            }}
            to={link}>
            <div className={classes.titleSurround}>
                <Typography variant={'h5'}>{title}</Typography>
                {appendix}
            </div>
            <Image imageSet={imageSet} imageClass={classes.menuImage} alt={title}
                   title={title}/>
        </Link>
    );
}

export default MobileMenuItem;
import TagManager from 'react-gtm-module';

let initialized = false;

const getGA = () => {
    if (!initialized) {
        TagManager.initialize({
            gtmId: process.env.REACT_APP_GTAG_ID || ''
        });
        initialized = true;
    }
    return TagManager;
}




export default getGA;
import {useMediaQuery} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {shallowEqual, useSelector} from 'react-redux';
import {AppState} from '../../types/types';
import React, {useCallback} from 'react';


export const useMenuColor = () => {

    const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    const {textColor, hoverColor, logoPath, menuPreview} = useSelector((state: AppState) => ({
        textColor: state.application.menu.color,
        hoverColor: state.application.menu.hoverColor,
        logoPath: state.application.menu.logoPath,
        menuPreview: state.application.menu.preview
    }), shallowEqual);

    const onMouseEnter = useCallback((e: React.MouseEvent<HTMLElement | SVGSVGElement, MouseEvent>) => {
        if (!isMobile && hoverColor) {
            e.currentTarget.style.color = hoverColor;
            e.currentTarget.style.fill = hoverColor;
        }
    }, [hoverColor, isMobile]);

    const onMouseLeave = useCallback((e: React.MouseEvent<HTMLElement | SVGSVGElement, MouseEvent>) => {
        if (!isMobile && textColor) {
            e.currentTarget.style.color = textColor;
            e.currentTarget.style.fill = textColor;
        }
    }, [textColor, isMobile]);

    return {onMouseEnter, onMouseLeave, textColor, hoverColor, logoPath, menuPreview};
}
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../../types/types';
import {getImageSet} from '../../../helper/helper';
import Image from '../../helper/Image';
import CloseIcon from '../../../icons/CloseIcon';
import {Link} from 'react-router-dom';
import {Routes} from '../../../routes/routes';
import {useLocalization} from '../../../localization/localization';

import headerWebPXS from '../../../images/dest/navigation/header-xs@1x.webp';
import headerWebPXS2x from '../../../images/dest/navigation/header-xs@2x.webp';
import headerWebPSM from '../../../images/dest/navigation/header-sm@1x.webp';
import headerWebPSM2x from '../../../images/dest/navigation/header-sm@2x.webp';
import headerWebPMD from '../../../images/dest/navigation/header-md@1x.webp';
import headerWebPMD2x from '../../../images/dest/navigation/header-md@2x.webp';
import headerWebPLG from '../../../images/dest/navigation/header-lg@1x.webp';
import headerWebPLG2x from '../../../images/dest/navigation/header-lg@2x.webp';
import headerPNGXS from '../../../images/dest/navigation/header-xs@1x.png';
import headerPNGXS2x from '../../../images/dest/navigation/header-xs@2x.png';
import headerPNGSM from '../../../images/dest/navigation/header-sm@1x.png';
import headerPNGSM2x from '../../../images/dest/navigation/header-sm@2x.png';
import headerPNGMD from '../../../images/dest/navigation/header-md@1x.png';
import headerPNGMD2x from '../../../images/dest/navigation/header-md@2x.png';
import headerPNGLG from '../../../images/dest/navigation/header-lg@1x.png';
import headerPNGLG2x from '../../../images/dest/navigation/header-lg@2x.png';

import collectionImageJPG from '../../../images/dest/navigation/categories/collection-lg@2x.jpg';
import collectionImageWebP from '../../../images/dest/navigation/categories/collection-lg@2x.webp';
import styleFinderImageWebP from '../../../images/dest/navigation/categories/style-finder-lg@2x.webp';
import styleFinderImageJPG from '../../../images/dest/navigation/categories/style-finder-lg@2x.jpg';
import colorWorldImageWebP from '../../../images/dest/navigation/categories/color-world-lg@2x.webp';
import colorWorldImageJPG from '../../../images/dest/navigation/categories/color-world-lg@2x.jpg';
import perfectYourSizeImageWebP from '../../../images/dest/navigation/categories/perfect-your-size-lg@2x.webp';
import perfectYourSizeImageJPG from '../../../images/dest/navigation/categories/perfect-your-size-lg@2x.jpg';
import brandWorldImageWebP from '../../../images/dest/navigation/categories/brand-world-lg@2x.webp';
import brandWorldImageJPG from '../../../images/dest/navigation/categories/brand-world-lg@2x.jpg';
import storyImageWebP from '../../../images/dest/navigation/categories/story-lg@2x.webp';
import storyImageJPG from '../../../images/dest/navigation/categories/story-lg@2x.jpg';
import cartImageWebP from '../../../images/dest/navigation/categories/cart-lg@2x.webp';
import cartImageJPG from '../../../images/dest/navigation/categories/cart-lg@2x.jpg';
import blogImageWebP from '../../../images/dest/navigation/categories/blog-lg@2x.webp';
import blogImageJPG from '../../../images/dest/navigation/categories/blog-lg@2x.jpg';

import {makeStyles} from 'tss-react/mui';
import {IconButton, SwipeableDrawer, Typography} from '@mui/material';
import {setShowCart, setShowMobileMenu} from '../../../reducer/application/application';
import MobileMenuCollapse from './mobile/MobileMenuCollapse';
import MobileLanguageSwitch from './mobile/MobileLanguageSwitch';
import MobileMenuItem from './mobile/MobileMenuItem';

import tiktokIcon from '../../../images/icons/tiktokMonoIcon.svg';
import twitterIcon from '../../../images/icons/twitterMonoIcon.svg';
import youtubeIcon from '../../../images/icons/youtubeMonoIcon.svg';
import pinterestIcon from '../../../images/icons/pinterestMonoIcon.svg';
import facebookIcon from '../../../images/icons/facebookMonoIcon.svg';
import instagramIcon from '../../../images/icons/instagramMonoIcon.svg';
import accountIcon from '../../../images/icons/mobileMenuAccountIcon.svg';
import contactIcon from '../../../images/icons/mobileMenuContactIcon.svg';
import locationIcon from '../../../images/icons/mobileMenuLocationIcon.svg';
import {denormalize} from '../../../helper/normalizeHelper';
import {LogoutActionAndSaga} from '../../../reducer/user/types';

const useStyles = makeStyles()((theme) => ({
    root: {
        boxSizing: 'border-box',
        width: '90%',
        backgroundColor: '#FCFBF8'
    },
    menu: {
        padding: theme.spacing(2),
        backgroundColor: '#F8F6F3'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2)
    },
    closeIcon: {
        color: theme.palette.secondary.main,
        width: 24,
        height: 24
    },
    headerImage: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    menuImage: {
        width: '56px',
        height: '56px'
    },
    footer: {
        backgroundColor: '#FCFBF8',
        padding: theme.spacing(2)
    },
    badge: {
        backgroundColor: '#444',
        color: '#FFF',
        fontSize: '12px',
        fontWeight: 500,
        borderRadius: '50%',
        lineHeight: '22px',
        width: '22px',
        textAlign: 'center',
        marginLeft: theme.spacing(1),
    },
    footerOptionContainer: {
        padding: theme.spacing(1, 0),
        borderBottom: 'solid 1px #979797'
    },
    footerOption: {
        ...theme.link,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#FCFBF8',
        padding: theme.spacing(1, 0),
        border: 0,
        width: '100%',
        cursor: 'pointer'
    },
    footerOptionLabel: {
        flex: 1,
        textAlign: 'left',
        marginLeft: '10px'
    },
    footerOptionItemContainer: {
        display: 'block',
        textDecoration: 'none',
        padding: theme.spacing(1, 0)
    },
    footerLink: {
        ...theme.link,
        display: 'block',
        background: 'none',
        width: '100%',
        textAlign: 'left',
        border: 'none',
        padding: theme.spacing(1, 0),
        cursor: 'pointer'
    },
    footerSocialContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
        margin: theme.spacing(1, 0, 16)
    }
}));


const MobileMenu: React.FC = () => {
    const {classes, theme} = useStyles();
    const open = useSelector((state: AppState) => state.application.showMobileMenu);
    const loggedIn = useSelector((state: AppState) => state.user.loggedIn);
    const admin = useSelector((state: AppState) => state.user.admin);
    const name = useSelector((state: AppState) => state.user.firstName);

    const [isOpen, setIsOpen] = useState(open);

    const wasOpen = useRef(open);

    const cartElements = useSelector((state: AppState) => state.cart.elements);

    const dispatch = useDispatch();
    const messages = useLocalization();

    const productsInCart = useMemo(() => denormalize(cartElements).length, [cartElements]);

    useEffect(() => {
        if (!wasOpen.current && open) {
            window.setTimeout(() => {
                setIsOpen(true);
            }, 100);
        } else {
            setIsOpen(open);
        }
        if (open) {
            wasOpen.current = true;
        }
    }, [open]);

    if (open || wasOpen.current) {
        const headerImageSet = getImageSet(headerWebPXS, headerWebPXS2x, headerWebPSM, headerWebPSM2x, headerWebPMD,
            headerWebPMD2x, headerWebPLG, headerWebPLG2x, headerPNGXS, headerPNGXS2x, headerPNGSM,
            headerPNGSM2x, headerPNGMD, headerPNGMD2x, headerPNGLG, headerPNGLG2x);

        const collectionImageSet = getImageSet(collectionImageWebP, collectionImageWebP, collectionImageWebP, collectionImageWebP, collectionImageWebP,
            collectionImageWebP, collectionImageWebP, collectionImageWebP, collectionImageJPG, collectionImageJPG, collectionImageJPG,
            collectionImageJPG, collectionImageJPG, collectionImageJPG, collectionImageJPG, collectionImageJPG);

        const styleFinderImageSet = getImageSet(styleFinderImageWebP, styleFinderImageWebP, styleFinderImageWebP, styleFinderImageWebP, styleFinderImageWebP,
            styleFinderImageWebP, styleFinderImageWebP, styleFinderImageWebP, styleFinderImageJPG, styleFinderImageJPG, styleFinderImageJPG,
            styleFinderImageJPG, styleFinderImageJPG, styleFinderImageJPG, styleFinderImageJPG, styleFinderImageJPG);

        const colorWorldImageSet = getImageSet(colorWorldImageWebP, colorWorldImageWebP, colorWorldImageWebP, colorWorldImageWebP, colorWorldImageWebP,
            colorWorldImageWebP, colorWorldImageWebP, colorWorldImageWebP, colorWorldImageJPG, colorWorldImageJPG, colorWorldImageJPG,
            colorWorldImageJPG, colorWorldImageJPG, colorWorldImageJPG, colorWorldImageJPG, colorWorldImageJPG);

        const perfectYourSizeImageSet = getImageSet(perfectYourSizeImageWebP, perfectYourSizeImageWebP, perfectYourSizeImageWebP, perfectYourSizeImageWebP, perfectYourSizeImageWebP,
            perfectYourSizeImageWebP, perfectYourSizeImageWebP, perfectYourSizeImageWebP, perfectYourSizeImageJPG, perfectYourSizeImageJPG, perfectYourSizeImageJPG,
            perfectYourSizeImageJPG, perfectYourSizeImageJPG, perfectYourSizeImageJPG, perfectYourSizeImageJPG, perfectYourSizeImageJPG);

        const brandWorldImageSet = getImageSet(brandWorldImageWebP, brandWorldImageWebP, brandWorldImageWebP, brandWorldImageWebP, brandWorldImageWebP,
            brandWorldImageWebP, brandWorldImageWebP, brandWorldImageWebP, brandWorldImageJPG, brandWorldImageJPG, brandWorldImageJPG,
            brandWorldImageJPG, brandWorldImageJPG, brandWorldImageJPG, brandWorldImageJPG, brandWorldImageJPG);

        const storyImageSet = getImageSet(storyImageWebP, storyImageWebP, storyImageWebP, storyImageWebP, storyImageWebP,
            storyImageWebP, storyImageWebP, storyImageWebP, storyImageJPG, storyImageJPG, storyImageJPG,
            storyImageJPG, storyImageJPG, storyImageJPG, storyImageJPG, storyImageJPG);

        const blogImageSet = getImageSet(blogImageWebP, blogImageWebP, blogImageWebP, blogImageWebP, blogImageWebP,
            blogImageWebP, blogImageWebP, blogImageWebP, blogImageJPG, blogImageJPG, blogImageJPG,
            blogImageJPG, blogImageJPG, blogImageJPG, blogImageJPG, blogImageJPG);

        const cartImageSet = getImageSet(cartImageWebP, cartImageWebP, cartImageWebP, cartImageWebP, cartImageWebP,
            cartImageWebP, cartImageWebP, cartImageWebP, cartImageJPG, cartImageJPG, cartImageJPG,
            cartImageJPG, cartImageJPG, cartImageJPG, cartImageJPG, cartImageJPG);

        return (
            <SwipeableDrawer open={isOpen}
                             classes={{
                                 paper: classes.root
                             }} onOpen={() => dispatch(setShowMobileMenu(true))}
                             onClose={() => dispatch(setShowMobileMenu(false))}>
                <div className={classes.menu}>
                    <div className={classes.header}>
                        <Typography variant={'subtitle1'}
                                    style={{lineHeight: '40px'}}>{messages.menu.title}</Typography>
                        <IconButton style={{marginRight: theme.spacing(-1)}}
                                    onClick={() => dispatch(setShowMobileMenu(false))}>
                            <CloseIcon className={classes.closeIcon}/>
                        </IconButton>
                    </div>

                    <Image imageSet={headerImageSet} alt={''} title={''} imageClass={classes.headerImage}/>

                    <MobileMenuItem title={messages.menu.collection} link={Routes.Products.Overview('')}
                                    imageSet={collectionImageSet}/>
                    <MobileMenuItem title={messages.menu.findYourStyle} link={Routes.FindYourStyle.Home()}
                                    imageSet={styleFinderImageSet}/>
                    <MobileMenuItem title={messages.menu.colorYourDay} link={Routes.ColorYourDay()}
                                    imageSet={colorWorldImageSet}/>
                    <MobileMenuItem title={messages.menu.passform} link={Routes.PerfectYourSize.Home('')}
                                    imageSet={perfectYourSizeImageSet}/>
                    <MobileMenuItem title={messages.menu.brandWorld} link={Routes.BrandWorld}
                                    imageSet={brandWorldImageSet}/>
                    <MobileMenuItem title={messages.menu.story} link={Routes.Story}
                                    imageSet={storyImageSet}/>
                    <MobileMenuItem title={messages.menu.blog} link={Routes.Blog.Base('')}
                                    imageSet={blogImageSet}/>
                    <MobileMenuItem title={messages.menu.cart} link={''} imageSet={cartImageSet}
                                    appendix={productsInCart > 0 &&
                                        <span className={classes.badge}>{productsInCart}</span>}
                                    onClick={() => {
                                        dispatch(setShowCart(true));
                                    }}/>

                </div>

                <div className={classes.footer}>
                    <MobileMenuCollapse title={loggedIn ? name : messages.menu.profileShort}
                                        icon={<img src={accountIcon} alt={''} title={''}/>}>
                        {!loggedIn ? (
                            <>
                                <Link
                                    className={classes.footerLink}
                                    onClick={() => dispatch(setShowMobileMenu(false))}
                                    to={Routes.User.Login}>
                                    <Typography variant='subtitle1'>{messages.menu.login}</Typography>
                                </Link>
                                <Link
                                    className={classes.footerLink}
                                    onClick={() => dispatch(setShowMobileMenu(false))}
                                    to={Routes.User.Register}>
                                    <Typography variant='subtitle1'>{messages.menu.register}</Typography>
                                </Link>
                            </>) : (
                            <>
                                {!admin &&
                                    <Link
                                        className={classes.footerLink}
                                        onClick={() => dispatch(setShowMobileMenu(false))}
                                        to={Routes.User.Orders}>
                                        <Typography variant='subtitle1'>{messages.menu.orders}</Typography>
                                    </Link>
                                }
                                {admin &&
                                    <Link
                                        className={classes.footerLink}
                                        onClick={() => dispatch(setShowMobileMenu(false))}
                                        to={Routes.Admin.Base(Routes.Admin.Orders)}
                                    >
                                        <Typography variant='subtitle1'>{messages.menu.adminItems.orders}</Typography>
                                    </Link>
                                }
                                {admin &&
                                    <Link
                                        className={classes.footerLink}
                                        onClick={() => dispatch(setShowMobileMenu(false))}
                                        to={Routes.Admin.Base(Routes.Admin.Products)}
                                    >
                                        <Typography variant='subtitle1'>{messages.menu.adminItems.products}</Typography>
                                    </Link>
                                }
                                {admin &&
                                    <Link
                                        className={classes.footerLink}
                                        onClick={() => dispatch(setShowMobileMenu(false))}
                                        to={Routes.Admin.Base(Routes.Admin.Colors)}>
                                        <Typography variant='subtitle1'>{messages.menu.adminItems.colors}</Typography>
                                    </Link>
                                }
                                {admin &&
                                    <Link
                                        className={classes.footerLink}
                                        onClick={() => dispatch(setShowMobileMenu(false))}
                                        to={Routes.Admin.Base(Routes.Admin.Blog.Base(''))}>
                                        <Typography variant='subtitle1'>{messages.menu.adminItems.blog}</Typography>
                                    </Link>
                                }
                                {admin &&
                                    <Link
                                        className={classes.footerLink}
                                        onClick={() => dispatch(setShowMobileMenu(false))}
                                        to={Routes.Admin.Base(Routes.Admin.FindYourStyle)}
                                    >
                                        <Typography variant='subtitle1'>{messages.menu.adminItems.styles}</Typography>
                                    </Link>
                                }
                                {admin &&
                                    <Link
                                        className={classes.footerLink}
                                        onClick={() => dispatch(setShowMobileMenu(false))}
                                        to={Routes.Admin.Base(Routes.Admin.Coupons)}
                                    >
                                        <Typography variant='subtitle1'>{messages.menu.adminItems.coupons}</Typography>
                                    </Link>
                                }
                                {admin &&
                                    <Link className={classes.footerLink}
                                          onClick={() => dispatch(setShowMobileMenu(false))}
                                          to={Routes.Admin.Base(Routes.Admin.NewsBar)}>
                                        <Typography variant='subtitle1'>{messages.menu.adminItems.newsBar}</Typography>
                                    </Link>
                                }
                                <Link
                                    className={classes.footerLink}
                                    onClick={() => dispatch(setShowMobileMenu(false))}
                                    to={Routes.User.UpdatePassword}>
                                    <Typography variant='subtitle1'>{messages.menu.updatePassword}</Typography>
                                </Link>
                                <button
                                    className={classes.footerLink}
                                    onClick={() => {
                                        dispatch(setShowMobileMenu(false));
                                        dispatch(LogoutActionAndSaga.action());
                                    }}>
                                    <Typography variant='subtitle1'>{messages.menu.logout}</Typography>
                                </button>
                            </>)
                        }
                    </MobileMenuCollapse>

                    <MobileMenuCollapse title={messages.menu.contact.title}
                                        icon={<img src={contactIcon} alt={''} title={''}/>}>
                        <a href={`mailto:${messages.menu.contact.mail}`} className={classes.footerOptionItemContainer}>
                            <Typography variant='body1'>{messages.menu.contact.mailQuestions}</Typography>
                            <Typography variant='subtitle1'>{messages.menu.contact.mail}</Typography>
                        </a>

                        <a href={`tel:${messages.menu.contact.tel}`} className={classes.footerOptionItemContainer}>
                            <Typography variant='body1'>{messages.menu.contact.telQuestions}</Typography>
                            <Typography variant='subtitle1'>{messages.menu.contact.tel}</Typography>
                        </a>

                        <div className={classes.footerOptionItemContainer}>
                            <Typography variant='body1'>{messages.menu.contact.openingHours}</Typography>
                        </div>
                    </MobileMenuCollapse>
                    <MobileLanguageSwitch/>

                    <div className={classes.footerOptionContainer}>
                        <Link
                            className={classes.footerOption}
                            onClick={() => dispatch(setShowMobileMenu(false))}
                            to={Routes.RoyalKingdom}>
                            <img src={locationIcon} alt={''} title={''}/>
                            <Typography variant='body1'
                                        className={classes.footerOptionLabel}>{messages.menu.storeFinder}</Typography>
                        </Link>
                    </div>

                    <div className={classes.footerSocialContainer}>
                        <a href={'https://www.facebook.com/royalloungeintimates'} target={'_blank'} rel={'noreferrer'}>
                            <img src={facebookIcon} title={''} alt={''}/>
                        </a>
                        <a href={'https://www.instagram.com/royalloungeintimates'} target={'_blank'} rel={'noreferrer'}>
                            <img src={instagramIcon} title={''} alt={''}/>
                        </a>
                        <a href={'https://twitter.com/royal_lounge_eu'} target={'_blank'} rel={'noreferrer'}>
                            <img src={twitterIcon} title={''} alt={''}/>
                        </a>
                        <a href={'https://www.youtube.com/channel/UCjQWW7xlt9VPYdxI1_Hrdqg'} target={'_blank'}
                           rel={'noreferrer'}>
                            <img src={youtubeIcon} title={''} alt={''}/>
                        </a>
                        <a href={'https://www.pinterest.de/RoyalLoungeIntimates/'} target={'_blank'} rel={'noreferrer'}>
                            <img src={pinterestIcon} title={''} alt={''}/>
                        </a>
                        <a href={'https://www.tiktok.com/@royalloungeintimates'} target={'_blank'} rel={'noreferrer'}>
                            <img src={tiktokIcon} title={''} alt={''}/>
                        </a>
                    </div>

                </div>
            </SwipeableDrawer>
        );
    }
    return null;
}

export default MobileMenu;
